






































import './scss/PopupTasksList.scss';
import Vue from 'vue';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { ITask } from "@store/modules/project-tasks/interfaces/Interfaces";

@Component({
    name: 'PopupTasksList',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
    }
})
export default class PopupTasksList extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
    }

    @Prop({ default: false }) disabledEscKey!: boolean;
    @Prop({ default: 'Задачи отсутствуют' }) noTaskText!: string;
    @Prop({ default: false }) hideSuccessAction!: boolean;
    @Prop({ default: 'default' }) typeSuccessButton!: string;

    @Emit('open-popup-task-details')
    emitOpenPopupTaskDetails(task: ITask): ITask {
        return task;
    }
    @Emit('open-popup-create-task')
    emitOpenPopupCreateTask(): void {}

    tasksList: ITask[] | null = null;

    get isTasksListExist(): boolean {
        let tasksExist: boolean = false;
        if (this.tasksList?.length && this.tasksList.length > 0) {
            tasksExist = true;
        }
        return tasksExist;
    }

    formattedIndex(index: number): string | number {
        let correctIndex: number = index + 1;
        return correctIndex <= 9 ? `0${correctIndex}` : correctIndex;
    }

    openPopup(): void {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.openPopup();
        }
    }

    closePopup(): void {
        this.$refs.basePopup.closePopup();
        this.resetData();
    }

    resetData(): void {
        this.tasksList = null;
    }

    setTasksList(tasks: ITask[]): void {
        this.tasksList = tasks;
    }

    onTaskClick(task: ITask): void {
        this.emitOpenPopupTaskDetails(task);
    }
}
